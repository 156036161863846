import Tooltip from "bootstrap/js/dist/tooltip";
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import { DateTime } from "luxon";

const euroFormat =
    new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'})

export function renderTable(id, resultType,  yearmonth, day, selectedProvider) {
  let url = "/resultaten/td?"
  if(resultType === "month" && yearmonth !== undefined) {
    url += "yearmonth=" + yearmonth;
  } else if((resultType === "day" || resultType === "live") && day !== undefined) {
    if(resultType === "live") {
        url = "/live/td?date=" + day;
    } else {
      url = "date=" + day;
    }
  }

  console.log("Using url", url)

  let initFilter = [];
  if(selectedProvider !== undefined && selectedProvider !== "") {
    console.log("Setting init filter for provider", selectedProvider)
    initFilter = [
      {field:"results.tradeProviderId", type:"=", value:selectedProvider}
    ];
  }

  let columns = [
    {formatter:"rownum", width:40,  headerSort:false,},
    {title: "Gebruiker", field: "results.username", headerSort:false, headerMenu:headerMenu,  resizable:true},
    {title:"Kenmerken", field: "results.charger", formatter:featureFormatter, formatterParams:{resultType:resultType}, headerHozAlign: "center", headerSort:false, maxWidth:200},
    {title: "Aanbieder", field: "provider.name", sorter: "string",maxWidth:200,  resizable:true},
    {title: "Aanbieder ID", field: "results.tradeProviderId", sorter: "string", visible: false}, // included for client side filtering
    {title: "Opbrengst", field: "results.batteryResult", mutateLink: "results.batteryResultRelative",  formatter: resultWithNote, formatterParams:{display:"absolute"},  sorter:"number", maxWidth:180,  resizable:true},
    {title: "Opbrengst €/kW", field: "results.batteryResultRelative", mutator: relativeBatteryResult, formatter: resultWithNote, formatterParams:{display:"relative"}, sorter:"number", visible: false,  resizable:true},
    {title: resultType === "live" ? "Batterij" : "Activiteit", field: "results.batteryCharged", formatter: activityFormatter, formatterParams:{resultType:resultType}, headerHozAlign: "center",  resizable:true, minWidth:150},
    {title: "<div style='color:black' class='d-none d-md-block'><i class='bi bi-sun'></i></div>", field: "results.solarResult", formatter: moneyFormatter, visible:false,
      headerTooltip: "Opbrengst van zonnepanelen", headerHozAlign: "center",  resizable:true},
    {title: "<i class='bi bi-plug'></i>", field: "results.chargerResult", formatter: moneyFormatter, visible:false, headerTooltip: "Opbrengst van laadpaal", headerHozAlign: "center", resizable:true},
  ]

  if(resultType === "live" && !day) {
    columns.push({title: "Laatste meting", field: "results.timestamp", formatter:localTimeAgoFormatter, headerHozAlign: "center", visible:true,  resizable:true});
  }

  let table = new Tabulator(id, {
    dependencies:{
      DateTime:DateTime,
    },
    maxHeight:"100%",
    rowHeight:50, //set rows to 50px height
    placeholder:"Er is geen data beschikbaar",
    // sortMode: "local", // TODO this can be set to ajax sorting
    filterMode: "local", // TODO this can be set to ajax
    // paginationMode: "local", // TODO this can be set to ajax
    // height: "900",  // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
    autoResize: true,
    layout: "fitColumns", 
    layoutColumnsOnNewData:true,
    debugInvalidOptions: true,
    resizableColumnGuide: true,
    resizableColumnFit: true,
    paginationSize:1,
    paginationCounter:"resultaten",
    columns: columns,
    initialSort:[             //set the initial sort order of the data
      {column:"results.batteryResult", dir:"desc"},
    ],
    initialFilter: initFilter,
    ajaxURL: url,
    ajaxConfig:{
      method:"GET", //set request type to Position
      headers: {
        "Content-type": 'application/json; charset=utf-8', //set specific content type
        "User-Agent": "Tabulator",
      },
    },

  });

  // register listener for provider filter
  let providerFilter = document.getElementById(resultType + "-provider-filter")
  if(providerFilter != null) {
    let providerOptions  = providerFilter.children;
    for (let i = 0; i < providerOptions.length; i++) {
      console.log("Adding click event to provider option", providerOptions[i])
      providerOptions[i].addEventListener("click", function (event) {
        event.preventDefault();
        updateProviderFilter(event.target, table);
      });
    }
  }

  // register listener for battery result toggle
  let batteryResultDisplay = document.getElementById("battery-result-toggle");
  batteryResultDisplay.addEventListener("change", (event) => {
    table.toggleColumn("results.batteryResult");
    table.toggleColumn("results.batteryResultRelative");
  });

  // make sure the tooltips in the table work once all the table data has been processed
  table.on("dataProcessed", function(data){
    //data - all data loaded into the table
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl,{container: 'body'}));
  });

  return table;
}

/**
 * Custom formatter to render the feature icons and info in a table column
 * @param cell
 * @param formatterParams
 * @param onRendered
 * @returns {string}
 */
let featureFormatter = function (cell, params, onRendered) {
  const data = cell.getRow().getData();
  console.log("row", data)

  let solarIcon = featureIcon(data.results.solar, "pv");
  let chargerIcon = featureIcon(data.results.charger, "charger");
  let measurements = numMeasurementsIcon(data.results.numberOfMeasurements ? data.results.numberOfMeasurements : 0);

  let html = `<div style="text-align: center;">`
  html += solarIcon + chargerIcon;

  if(params.resultType === "live") {
    html += measurements;
    if(data.results.mode === "self_consumption") {
      html += selfConsumptionIcon;
    }
  } else {
    html += batteryInfo(data);
  }

  return html + `</div>`;
}

let numMeasurementsIcon = function(numMeasurements) {
  return   `<span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Metingen: ${numMeasurements} ontvangen."> \
              <i class="bi bi-arrow-repeat"></i> \
            </span>`;
}

let selfConsumptionIcon =
  `<span data-bs-toggle="tooltip" data-bs-placement="bottom" \ 
        data-bs-title="Batterij staat in zelfconsumptie-modus, hierbij handelt de batterij niet op de onbalansprijzen maar enkel om het eigen verbruik af te dekken met eventuele zonnestroom.">
    <i class="bi bi-house-exclamation px-md-1" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Zelfconsumptie-modus"></i>
  </span>`;

/**
 * Custom formatter for money values, defaults to - if no value is present
 * @param cell
 * @param params
 * @param onRendered
 * @returns {string|string}
 */
let moneyFormatter = function (cell, params, onRendered) {
  return cell.getValue() ? euroFormat.format(cell.getValue()) : "-";
}

/**
 * Formatter for the activity column, shows the charged and discharged battery values
 * @param cell
 * @param params
 * @param onRendered
 * @returns {string}
 */
let activityFormatter = function (cell, params, onRendered) {
  const data = cell.getRow().getData();

  let html = `<div class="d-flex flex-row justify-content-evenly align-items-end">`;
  if(params.resultType === "live") {
    let percentage = data.results.batteryPercentage ? (data.results.batteryPercentage + "%") : "-";
    html += `<div>${percentage}</div>`
  }

  html += activityIcon(data.results.batteryCharged, true)
  html += activityIcon(data.results.batteryDischarged, false)
  return html + `</div>`;
}

/**
 * Helper method to render a battery activity icon (arrow up or down)
 * in the correct color and with teh provided value
 * @param value
 * @param isCharged
 * @returns {string}
 */
let activityIcon = function (value, isCharged) {
  let color = isCharged ? "#01ab01" : "#d90101";
  if (!value) {
    return `<div style="color:${color}" class="d-none d-md-block"> - </div>`;
  }

  return `<div style="color:${color}" class="d-none d-md-block"> \
            <i class="bi bi-arrow-up-short" style="font-size: 1.1rem; color: ${color}"></i>${value} \
            <span style="font-size:0.5em">kWh</span> \
      </div> `
}

/**
 * Formatter to render a feature icon for that can be used in a table column
 * @param choice
 * @param feature
 * @returns {string}
 */
function featureIcon(choice, feature) {
  if (choice) {
    let iconClass = feature === "pv" ? "bi-sun" : "bi-plug";
    let feat = feature === "pv" ? "Zonnepanelen" : "Laadpaal"
    switch (choice) {
      case "yes_powerplay":
        return `<i class='bi ${iconClass} px-md-1' data-bs-toggle="tooltip" data-bs-placement="bottom" \
            data-bs-title="${feat} met afschakelen op basis van onbalans" style="font-size: 1.1rem; color:#108d10"></i>`
      case "yes":
        return `<i class="bi ${iconClass} px-md-1" data-bs-toggle="tooltip" data-bs-placement="bottom" \
            data-bs-title="${feat} aanwezig" style="font-size: 1.1rem; color:orange"></i>`;
      case "no":
        return `<i class="bi ${iconClass} px-md-1" data-bs-toggle="tooltip" data-bs-placement="bottom" \
            data-bs-title="${feat} niet aanwezig" style="font-size: 1.1rem; color:#4d5154"></i>`;
    }
  }
}

/**
 * Helper method to get the battery and inverter capacity.
 * @param data the row data
 * @returns html containing the battery info
 */
function batteryInfo(data) {
  let batteryText = data.battery?.name ? data.battery.name : data.results.customBatteryDescription;
  let inverterText = data.inverter?.name ? data.inverter.name : data.results.customInverterDescription;

  let batteryCapacity = Number(data.battery?.capacity).toFixed(0);
  let inverterCapacity = Number(data.inverter?.dischargePower).toFixed(0);

  return `<span class="batteryinfo"> \
    <span data-bs-toggle="tooltip" data-bs-placement="bottom" \
          data-bs-title="Batterij: ${batteryText}"> \
              ${batteryCapacity} <span class="unit">kWh</span> \
    </span>, \
    <span data-bs-toggle="tooltip" data-bs-placement="bottom" \
          data-bs-title="Omvormer: ${inverterText} kW laden"> \
              ${inverterCapacity} <span class="unit">kW</span> \
    </span> \
  </span>`
}

/**
 * Returns the html for the battery result column, uses the provided note and batteryResult.
 * The latter can be the relative result (€/kW) or absolute result (€)
 */
function batteryResultHtml(note, batteryResult) {
  let noteHtml = note ? `<i class="bi bi-exclamation-lg" style="font-size: 1rem; color:darkred" \
              data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="${note}"></i>` : "";

  return `<div class="row justify-content-center">
            <div class="col-xl-2 d-none d-xl-block"></div> \
            <div class="col-xl-7 col-lg-9 col-sm-12"> \
                  <div class="d-flex flex-row justify-content-between align-items-end"> \
                    <div class="float-left"> \
                         ${noteHtml} \
                    </div> \
                    <div class="float-right" style="text-align:right">${batteryResult}</div> \
            </div> \
            <div class="col-xl-3 d-none d-xl-block"></div> \
          </div>`;
}

/**
 * Formatter to render the result with a note icon if a note is present
 * @returns the correct html for the result column
 */
function resultWithNote(cell, params, onRendered) {
  let data = cell.getRow().getData();
  let note = data.results.note ? data.results.note : "";

  let batteryResult;
  if(params.display === "relative") {
    batteryResult = data.results?.batteryResultRelative ? euroFormat.format(data.results.batteryResultRelative) : "-";
  } else {
    batteryResult = data.results?.batteryResult ? euroFormat.format(data.results.batteryResult) : "-";
  }

  return batteryResultHtml(note, batteryResult)
}

/**
 * Mutator function to calculate the relative battery result (€/kWh)
 */
function relativeBatteryResult(value, data) {
  let inverterPower = data.inverter?.dischargePower; // in kwh
  if(!inverterPower) {
    console.error("Inverter power not set, defaulting to 1");
    inverterPower = 1;
  }

  return data.results?.batteryResult ? (data.results.batteryResult / inverterPower) : null;
}

/**
 * Helper method to build a new url based on the current url, swapping or setting the selected provider id
 */
function buildNewUrl(currentUrl, providerId) {
  let newUrl;
  if (currentUrl.includes("aanbieder")) {
    if (providerId !== "") {
      newUrl = currentUrl.replace(currentUrl.split("/").pop(), providerId);
    } else {
      newUrl = currentUrl.replace(new RegExp("\/aanbieder\/[\\s\\S]*"), "");
    }
  } else if (providerId !== "") {
    // no filter set yet
    newUrl = currentUrl + "/aanbieder/" + providerId;
  }
  return newUrl;
}

/**
 * Updates the provider filter in the table and the URL
 */
function updateProviderFilter(target, table) {
  let currentUrl =  window.location.href;
  let providerId = target.getAttribute("data-id");

  // create the corresponding url
  let newUrl = buildNewUrl(currentUrl, providerId);

  // update the table filter
  if(providerId !== "") {
    table.setFilter("results.tradeProviderId", "=", providerId);
  } else {
    table.getFilters().forEach(filter => {
      if (filter.field === "results.tradeProviderId") {
        table.removeFilter(filter.field, filter.type, filter.value);
      }
    });
    console.log("Table filters after removing provider filter", table.getFilters());
  }

  // push the new url
  console.log("Pushing new URL", newUrl);
  history.pushState({}, "", newUrl);

  // mark the clicked button as button
  for (const child of target.parentElement.children) {
    if(child === target) {
      child.classList.add("active");
    } else {
      child.classList.remove("active");
    }
  }

  updateDateFilterButtons(providerId);
}

/**
 * Marks the correct provider filter button as active
 * @param providerId
 */
function updateDateFilterButtons(providerId) {
  let navElements = document.getElementById("month-nav").getElementsByTagName("a")

  console.log("Page links", navElements.length)
  for(const link of navElements) {
    if(link.role !== "button") {
      console.log("Updating link", link)
      console.log("Current url", link.href)
      let newUrl = buildNewUrl(link.href, providerId);
      link.setAttribute("href", newUrl);
    }
  }
}

/**
 * Format a cells timestamp value to time a go in local time
 */
function localTimeAgoFormatter(cell, params, onRendered) {
  return `<td style="text-align: center;"> \
      <span class="d-lg-none"> \
        ${localTimeAgoCompact(cell.getValue())} \
      </span> \
      <span class="d-none d-lg-inline"> \
          ${localTimeAgo(cell.getValue())} \
      </span> \
  </td>`;
}

/**
 * convert iso timestamp to compact local time ago
 */
function localTimeAgoCompact(timestamp) {
  let millisDiff = DateTime.utc().toMillis() -  DateTime.fromISO(timestamp).toMillis();
  let secDiff = millisDiff / 1000;

  if(secDiff < 60) {
    return secDiff + " sec";
  } else if (secDiff < 3600) {
    let minutesDifference = secDiff / 60;
    return ~~minutesDifference + " min";
  } else {
    let hoursDifference = secDiff / 3600;
    return ~~hoursDifference + " uur";
  }
}

/**
 * convert iso timestamp to local time ago
 */
function localTimeAgo(timestamp) {
  let millisDiff = DateTime.utc().toMillis() -  DateTime.fromISO(timestamp).toMillis();
  let secDiff = millisDiff / 1000;

  if(secDiff < 60) {
    return ~~secDiff + (~~secDiff === 1 ? " seconde geleden" : " seconden geleden");
  } else if (secDiff < 3600) {
    let minutesDifference = secDiff / 60;
    return ~~minutesDifference + (~~minutesDifference === 1 ? " minuut geleden" : " minuten geleden");
  } else {
    let hoursDifference = secDiff / 3600;
    return ~~hoursDifference + " uur geleden";
  }
}

/**
 * define column header menu as column visibility toggle
 */
let headerMenu = function(){
  let menu = [];
  const columns = this.getColumns();

  for(let column of columns){
    //create checkbox element using font awesome icons
    let icon = document.createElement("i");
    icon.classList.add("fas");
    icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

    //build label
    let label = document.createElement("span");
    let title = document.createElement("span");

    let colDef = column.getDefinition();
    if(colDef.title === undefined || colDef.field === "results.tradeProviderId" || colDef.field === "results.username") {
        continue;
    }
    if(colDef.field === "results.chargerResult") {
      title.textContent = " Oplader";
    } else if (colDef.field === "results.solarResult") {
      title.textContent = " Zonnepanelen";
    } else {
      title.textContent = " " + colDef.title;
    }

    label.appendChild(icon);
    label.appendChild(title);

    //create menu item
    menu.push({
      label:label,
      action:function(e){
        //prevent menu closing
        e.stopPropagation();

        //toggle current column visibility
        column.toggle();

        //change menu item icon
        if(column.isVisible()){
          icon.classList.remove("fa-square");
          icon.classList.add("fa-check-square");
        }else{
          icon.classList.remove("fa-check-square");
          icon.classList.add("fa-square");
        }
      }
    });
  }

  return menu;
};