import "chart.js";
import Tooltip from "bootstrap/js/dist/tooltip";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Chart from 'chart.js/auto';
import {nl} from "date-fns/locale";
import "chartjs-adapter-date-fns";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {renderTable} from "./tables.js";

Chart.register(ChartDataLabels);
// Change default options for ALL charts
Chart.defaults.set('plugins.datalabels', {
  display: false,
});
window.Chart = Chart;
window.collapse = Collapse;


/**
 * Landrick theme menu handling
 */
// Toggle menu
window.toggleMenu = function () {
  document.getElementById('isToggle').classList.toggle('open');
  let isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
    isOpen.style.display = "none";
  } else {
    isOpen.style.display = "block";
  }
};

/**
 * Initialize a table with Tabulator
 * @param id                id of the div that represents the table
 * @param resultType        type of the result (live, day, month, year)
 * @param yearmonth         yearmonth to select
 * @param day               day to select (yearmonthday)
 * @param selectedProvider  provider id to filter by
 * @returns {*}             an instance of Tabulator
 */
window.initTable = function(id, resultType, yearmonth, day, selectedProvider) {
    return renderTable(id, resultType, yearmonth, day, selectedProvider);
}

//Menu Active
function getClosest(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.matches ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
              i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {
          }
          return i > -1;
        };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}

// highlight active nav item
function activeNavItem() {
  let navItems = document.getElementsByClassName('nav-link');

  let matchingItem = null;
  for (let i = 0; i < navItems.length; i++) {
    let item = navItems[i];
    if (item.href === window.location.href) {
      matchingItem = item;
    } else {
      item.classList.remove('active');
    }
  }

  if (matchingItem) {
    matchingItem.classList.add('active');
  }
}

function activateMenu() {
  var menuItems = document.getElementsByClassName("sub-menu-item");
  if (menuItems) {
    var matchingMenuItem = null;
    for (var idx = 0; idx < menuItems.length; idx++) {
      if (menuItems[idx].href === window.location.href) {
        matchingMenuItem = menuItems[idx];
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      var immediateParent = getClosest(matchingMenuItem, 'li');
      if (immediateParent) {
        immediateParent.classList.add('active');
      }

      var parent = getClosest(matchingMenuItem, '.parent-menu-item');
      if (parent) {
        parent.classList.add('active');
        var parentMenuitem = parent.querySelector('.menu-item');
        if (parentMenuitem) {
          parentMenuitem.classList.add('active');
        }
        var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      } else {
        var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      }
    }
  }
}

function windowScroll() {
  const navbar = document.getElementById("topnav");
  if(navbar!=null){
    if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }
}

window.addEventListener('scroll', (ev) => {
  ev.preventDefault();
  windowScroll();
})

window.onload = function loader() {
  activeNavItem();

  // render charts
  if(typeof chartConfigurations !== 'undefined') {
    for (let i = 0; i < chartConfigurations.length; i++) {
      const config = chartConfigurations[i];
      if (typeof config.configuration.options === 'undefined') {
        config.configuration.options = {};
      }

      let options = config.configuration.options;
      options.animation = {
        duration: 0,
      }
      options.responsive = true

      // add the date locale for x time axis
      if (typeof options.scales !== 'undefined') {
        if (typeof options.scales.x !== 'undefined') {
          if (options.scales.x.type === 'time') {
            options.scales.x.adapters = {
              date: {
                locale: nl
              }
            }
          }
        }
      }
      new Chart(config.element, config.configuration);
    }
  }

  const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
  const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl));

  // enable tooltips
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl,{container: 'body'}));
  
  document.querySelectorAll('.collapse').forEach(collapseElement => {
    console.log("collapse bind", collapseElement)
    new Collapse(collapseElement);
  });
  // Initialize dropdown elements
  document.querySelectorAll('.dropdown-toggle').forEach(dropdownToggleEl => {
    new Dropdown(dropdownToggleEl);
  });
}

// track JS errors in Plausible
try {
  window.plausible =
      window.plausible ||
      function () {
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };
  
  window.addEventListener('error', (e) => {
    plausible('JavaScript Error', {
      props: {
        message: e.message,
        filename: e.filename,
        lineno: e.lineno,
        colno: e.colno,
        url: window.location.href,
      },
    });
  });
  
  window.addEventListener('unhandledrejection', function (e) {
    plausible('JavaScript Error', {
      props: {
        message:
            'Unhandled rejection (reason: ' +
            (e.reason && e.reason.stack ? e.reason.stack : e.reason) +
            ').',
        url: window.location.href,
      },
    });
  });
} catch (e) {
  /* NOOP */
}